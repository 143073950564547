export const COLUMN_WIDTH: LuminaObject = {
    openingName: '20%',
    location: '15%',
    status: '15%',
    staffingType: '12%',
    applicantPreview: '10%'
};

export const GENDER_DICT: LuminaObject = {
    male: 'Laki-Laki',
    female: 'Perempuan',
    'male/female': 'Semua'
};

export const SALARY_RANGE: LuminaObject = {
    1: 'rb',
    2: 'jt',
    3: 'mil',
    4: 'tril'
};

export const JOB_PROMOTION_DEFAULT_PRICING_PLAN = '30 Hari';
export const JOB_LIMIT_EXTENSION_DEFAULT_PRICING_PLAN = '5 lowongan';

export const JOB_OPENING_STATUS = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    EXPIRED: 'expired',
    MANUALLY_DEACTIVATED: 'manually_deactivated',
    PENDING_APPROVAL: 'pending_approval',
    REJECTED: 'rejected',
    SUSPENDED: 'suspended'
};
