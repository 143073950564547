import { baseApi } from 'redux/api/baseApi';

const JOB_OPENING_BASE_URL = 'jobs/company/job-openings/';
const ASSESSMENT_BASE_URL = 'jobs/company/assessments/';
const WORKER_BASE_URL = 'workers/';
const JOB_PROMOTION_PURCHASE_BASE_URL = 'payments/companies/';

export const jobOpeningApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getJobOpenings: builder.query<ListResponseData<JobOpening>, JobOpeningQueryParams>({
            query: (params: JobOpeningQueryParams) => ({
                url: `${JOB_OPENING_BASE_URL}`,
                params
            }),
            providesTags: (result) =>
                // is result available?
                result
                    ? // successful query
                      [
                          ...result.data.map(({ id }) => ({ type: 'Application', id } as const)),
                          { type: 'JobOpening', id: 'LIST' }
                      ]
                    : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
                      [{ type: 'JobOpening', id: 'LIST' }]
        }),
        getJobOpeningsForInfiniteScroll: builder.query<
            ListResponseData<JobOpening>,
            JobOpeningQueryParams
        >({
            query: (params: JobOpeningQueryParams) => ({
                url: `${JOB_OPENING_BASE_URL}`,
                params
            }),
            providesTags: (result) =>
                // is result available?
                result
                    ? // successful query
                      [
                          ...result.data.map(({ id }) => ({ type: 'Application', id } as const)),
                          { type: 'JobOpening', id: 'LIST' }
                      ]
                    : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
                      [{ type: 'JobOpening', id: 'LIST' }]
        }),
        getJobOpening: builder.query<JobOpening, string>({
            query: (id: string) => ({
                url: `${JOB_OPENING_BASE_URL}${id}/`
            }),
            providesTags: (result, error, id) => [{ type: 'JobOpening', id }]
        }),
        getJobOpeningSimplified: builder.query<JobOpeningSimplified, string>({
            query: (id: string) => ({
                url: `${JOB_OPENING_BASE_URL}${id}/simplified/`
            }),
            providesTags: (result, error, id) => [{ type: 'JobOpening', id }]
        }),
        getJobOpeningTemplateList: builder.query<
            JobOpeningTemplateListResponse<JobOpeningTemplateSimplified>,
            void
        >({
            query: () => `${JOB_OPENING_BASE_URL}templates/`
        }),
        getJobOpeningTemplateDetail: builder.query<JobOpeningTemplateDetail, string>({
            query: (id: string) => ({
                url: `${JOB_OPENING_BASE_URL}templates/${id}/`
            })
        }),
        createJobOpening: builder.mutation<JobOpening, JobOpeningInputData>({
            query: (data: JobOpeningInputData) => ({
                url: `${JOB_OPENING_BASE_URL}`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: [
                { type: 'JobOpening', id: 'LIST' },
                { type: 'JobOpeningByApplicant', id: 'LIST' },
                { type: 'LuminaCredit' },
                { type: 'SubscriptionDetail' },
                { type: 'FeatureFlag' }
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                if (data) {
                    dispatch(
                        jobOpeningApi.endpoints.getJobOpeningCategories.initiate(undefined, {
                            forceRefetch: true
                        })
                    );
                    dispatch(
                        jobOpeningApi.endpoints.getJobOpeningCities.initiate(undefined, {
                            forceRefetch: true
                        })
                    );
                    dispatch(
                        jobOpeningApi.endpoints.getJobOpeningStatus.initiate(undefined, {
                            forceRefetch: true
                        })
                    );
                }
            }
        }),
        deleteJobOpening: builder.mutation<JobOpening, string>({
            query: (id: string) => ({
                url: `${JOB_OPENING_BASE_URL}${id}/`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'JobOpening', id: 'LIST' }],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                if (data) {
                    dispatch(
                        jobOpeningApi.endpoints.getJobOpeningCategories.initiate(undefined, {
                            forceRefetch: true
                        })
                    );
                    dispatch(
                        jobOpeningApi.endpoints.getJobOpeningCities.initiate(undefined, {
                            forceRefetch: true
                        })
                    );
                    dispatch(
                        jobOpeningApi.endpoints.getJobOpeningStatus.initiate(undefined, {
                            forceRefetch: true
                        })
                    );
                }
            }
        }),
        updateJobOpening: builder.mutation<JobOpening, { data: JobOpeningInputData; id: string }>({
            query: ({ data, id }) => ({
                url: `${JOB_OPENING_BASE_URL}${id}/`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, data) => [
                { type: 'JobOpening', id: data.id },
                { type: 'JobOpening', id: 'LIST' }
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;

                if (data) {
                    dispatch(
                        jobOpeningApi.endpoints.getJobOpeningCategories.initiate(undefined, {
                            forceRefetch: true
                        })
                    );
                    dispatch(
                        jobOpeningApi.endpoints.getJobOpeningCities.initiate(undefined, {
                            forceRefetch: true
                        })
                    );
                    dispatch(
                        jobOpeningApi.endpoints.getJobOpeningStatus.initiate(undefined, {
                            forceRefetch: true
                        })
                    );
                }
            }
        }),
        getJobOpeningStatus: builder.query<ResponseData<JobOpeningStatus>, void>({
            query: () => `${JOB_OPENING_BASE_URL}status/v2/`
        }),
        getJobOpeningCities: builder.query<ResponseData<JobOpeningCity>, void>({
            query: () => `${JOB_OPENING_BASE_URL}cities/`
        }),
        getJobOpeningCategories: builder.query<ResponseData<JobOpeningCategory>, void>({
            query: () => `${JOB_OPENING_BASE_URL}categories/`
        }),
        updateJobOpeningPublicationStatus: builder.mutation<
            JobOpening,
            { data: UpdateJobOpeningPublicationStatusInputData; id: string }
        >({
            query: ({ id, data }) => ({
                url: `${JOB_OPENING_BASE_URL}${id}/publication-status/`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: (result, error, data) => [
                { type: 'JobOpening', id: data.id },
                { type: 'JobOpening', id: 'LIST' }
            ]
        }),
        getIsJobOpeningEligibleForWAJobApplication: builder.query<GetWAEligibilityStatus, void>({
            query: () => `${JOB_OPENING_BASE_URL}contact-whatsapp-eligibility/`
        }),
        getCreationLimitValidation: builder.query<JobCreationLimit, void>({
            query: () => `${JOB_OPENING_BASE_URL}creation-limit-validation/`,
            providesTags: [{ type: 'JobOpening', id: 'CREATION_LIMIT' }]
        }),
        getQuickAddAssessmentRecommendation: builder.query<
            AssessmentRecommendation[],
            AssessmentParams
        >({
            query: (params: AssessmentParams) => ({
                url: `${ASSESSMENT_BASE_URL}recommendation/`,
                params
            }),
            transformResponse: (response: AssessmentRecommendationResponse) => {
                return response.assessments;
            }
        }),
        getJobOpeningDocumentType: builder.query<JobOpeningDocumentItem[], void>({
            query: () => ({
                url: `${WORKER_BASE_URL}document-types/`
            }),
            transformResponse: (
                response: ResponseData<JobOpeningDocument>
            ): JobOpeningDocumentItem[] => {
                return response.data.map((document: JobOpeningDocument) => ({
                    value: document.slug,
                    label: document.name
                }));
            }
        }),
        createJobOpeningPromoted: builder.mutation<JobOpeningPromoted, JobOpeningPromotedInputData>(
            {
                query: (data: JobOpeningPromotedInputData) => ({
                    url: `/jobs/company/job-promotion-submissions/`,
                    method: 'POST',
                    body: data
                }),
                invalidatesTags: (result, error, data) => [
                    { type: 'JobOpening', id: data.job_opening_id },
                    { type: 'JobOpening', id: 'LIST' }
                ]
            }
        ),
        getJobWorkingSchedule: builder.query<JobWorkingScheduleResponseData, void>({
            query: () => ({
                url: '/jobs/company/job-working-schedules/'
            })
        }),
        getJobPromotionPricingPlans: builder.query<ResponseData<JobPromotionPricingPlan>, void>({
            query: () => ({
                url: `${JOB_PROMOTION_PURCHASE_BASE_URL}pricing-plans/job-promotion/`
            })
        }),
        createPurchaseJobPromotion: builder.mutation<
            PurchaseJobPromotionResponseData,
            PurchaseJobPromotionInputData
        >({
            query: (data: PurchaseJobPromotionInputData) => ({
                url: `${JOB_PROMOTION_PURCHASE_BASE_URL}transactions/job-promotion/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (_, error, data) => [
                { type: 'JobOpening', id: data.job_opening_id },
                { type: 'JobOpening', id: 'LIST' },
                'LuminaCredit',
                'SubscriptionDetail',
                'FeatureFlag'
            ]
        }),
        createPurchaseJobPromotionQuotaBased: builder.mutation<
            JobPromotionQuotaBasedResponseData,
            JobPromotionQuotaBasedInputData
        >({
            query: (data: JobPromotionQuotaBasedInputData) => ({
                url: `${JOB_PROMOTION_PURCHASE_BASE_URL}transactions/job-promotion/quota/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: (_, error, data) => [
                { type: 'JobOpening', id: data.job_opening_id },
                { type: 'JobOpening', id: 'LIST' },
                { type: 'SubscriptionDetail' },
                'LuminaCredit',
                'FeatureFlag'
            ]
        }),
        getJobLimitExtensionPricingPlans: builder.query<
            ResponseData<JobLimitExtensionPricingPlan>,
            void
        >({
            query: () => ({
                url: `${JOB_PROMOTION_PURCHASE_BASE_URL}pricing-plans/job-limit-extension/`
            })
        }),
        createPurchaseJobLimitExtension: builder.mutation<
            PurchaseJobLimitExtensionResponseData,
            PurchaseJobLimitExtensionInputData
        >({
            query: (data: PurchaseJobLimitExtensionInputData) => ({
                url: `${JOB_PROMOTION_PURCHASE_BASE_URL}transactions/job-limit-extension/`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: () => [
                { type: 'JobOpening', id: 'LIST' },
                { type: 'JobOpening', id: 'CREATION_LIMIT' },
                'LuminaCredit',
                'SubscriptionDetail',
                'FeatureFlag'
            ]
        }),
        getJobOpeningStatistics: builder.query<
            JobPromotionStatistics,
            JobPromotionStatisticsQueryParms
        >({
            query: ({ job_opening_id, promotion_start_at, promotion_end_at }) => ({
                url: `${JOB_OPENING_BASE_URL}${job_opening_id}/promotion-statistics/`,
                params: { promotion_start_at, promotion_end_at }
            })
        }),
        getJobOpeningActivityLogs: builder.query<
            ListResponseData<JobOpeningActivityLogItem>,
            JobOpeningActivityLogQueryParams
        >({
            query: ({ page, job_opening_id }) => ({
                url: `${JOB_OPENING_BASE_URL}${job_opening_id}/activity-logs/`,
                params: { limit: 6, page }
            })
        }),
        getJobOpeningCounter: builder.query<JobOpeningCount, void>({
            query: () => ({
                url: `${JOB_OPENING_BASE_URL}counter/`
            }),
            providesTags: [{ type: 'JobOpening', id: 'LIST' }]
        })
    }),
    overrideExisting: false
});

export const {
    useCreateJobOpeningMutation,
    useGetJobOpeningsQuery,
    useGetJobOpeningQuery,
    useDeleteJobOpeningMutation,
    useUpdateJobOpeningMutation,
    useGetJobOpeningStatusQuery,
    useGetJobOpeningCitiesQuery,
    useGetJobOpeningCategoriesQuery,
    useGetCreationLimitValidationQuery,
    useGetJobOpeningsForInfiniteScrollQuery,
    useLazyGetJobOpeningsForInfiniteScrollQuery,
    useUpdateJobOpeningPublicationStatusMutation,
    useGetIsJobOpeningEligibleForWAJobApplicationQuery,
    useGetQuickAddAssessmentRecommendationQuery,
    useGetJobOpeningDocumentTypeQuery,
    useGetJobOpeningSimplifiedQuery,
    useCreateJobOpeningPromotedMutation,
    useGetJobWorkingScheduleQuery,
    useGetJobPromotionPricingPlansQuery,
    useCreatePurchaseJobPromotionMutation,
    useCreatePurchaseJobPromotionQuotaBasedMutation,
    useGetJobLimitExtensionPricingPlansQuery,
    useCreatePurchaseJobLimitExtensionMutation,
    useGetJobOpeningStatisticsQuery,
    useGetJobOpeningActivityLogsQuery,
    useLazyGetJobOpeningActivityLogsQuery,
    useGetJobOpeningTemplateListQuery,
    useGetJobOpeningTemplateDetailQuery,
    useGetJobOpeningCounterQuery
} = jobOpeningApi;
